@font-face {
  font-family: 'Regular';
  src: local('Lato-Regular'), url(./../assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Bold';
  src: local('Lato-Bold'), url(./../assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lora-Regular';
  src: local('Lora-Regular'), url(./../assets/fonts/Lora-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lora-SemiBold';
  src: local('Lora-SemiBold'), url(./../assets/fonts/Lora-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lora-Bold';
  src: local('Lora-Bold'), url(./../assets/fonts/Lora-Bold.ttf) format('truetype');
}

body{
  font-family: 'Regular';
  color: #404040 !important;
}

p, ol li, ul li{
  color: #757575;
  margin-bottom: 0 !important;
}

strong{
  color: #404040;
}

.text-justify{
  text-align: justify;
}

.iso-title{
  font-weight: bold;
  color: #000;
}

.iso-desc{
  color: #000;
  font-weight: bold;
  font-size: .8rem;
}

.iso-no{
  font-size: .8rem;
}

.wrap-hidden{
  width: 100%;
  overflow: hidden;
}

.pointer{
  cursor: pointer;
}

.logo-main{
  max-width: 90px;
}

.navbar-toggler{
  border: 0px !important;
}

.navbar-nav .nav-link{
  text-align: center;
}

.btn-brand {
  background-color: $brand-color !important;
  color: white !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border-radius: 3px !important;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active{
  font-family: 'Bold';
}

nav {
  background-color: #fff;
  box-shadow: 0px 1px 20px 1px rgba(176,176,176,0.30);
  -webkit-box-shadow: 0px 1px 20px 1px rgba(176,176,176,0.30);
  -moz-box-shadow: 0px 1px 20px 1px rgba(176,176,176,0.30);
}

.burger-menu{
  width: 40px;
  height: 40px;
}

.bg-pink{
  background-color: $bg-pink;
}

.error{
  color: $brand-color;
  font-size: .9rem;
}

.text-brand{
  color: $brand-color;
}

.header {
  font-family: 'Lora-Bold';
  font-size: 2rem;
  line-height: 2.7rem;
}

.subheader {
  font-family: 'Lora-SemiBold';
  font-size: 1.6rem;
}

.title {
  font-family: 'Lora-SemiBold';
  font-size: 1.2rem;
}

.header-desc{
  text-align: center;
  max-width: 730px;
  margin: 0 auto;
  color: #757575;
}

.relative {
  position: relative;
  clear: both;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #21252957 !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #21252957 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #21252957 !important;
}

.bg-header, .bg-header-without-box-dot{
  background: rgb(254,243,242);
  background: linear-gradient(180deg, rgba(254,243,242,1) 0%, rgba(254,243,242,1) 70%, rgba(255,255,255,0) 98%);

  .fade-in-text{
    position: relative;
    z-index: 2;
  }
}

.bg-header::after{
  content: '';
  width: 10px;
  height: 10px;
  background-color: $green-color;
  position: absolute;
  top: 12vh;
  left: 10vw;
  transform: translate(4.5em,3em);
  animation: perspec-back 5s linear infinite;
}

.bg-header::before{
  content: '';
  width: 10px;
  height: 10px;
  background-color: $blue-color;
  position: absolute;
  top: 25vh;
  left: 20vw;
  animation: perspec-front 9s linear infinite;
}

.box-dot::after{
  content: '';
  width: 10px;
  height: 10px;
  background-color: $yellow-color;
  position: absolute;
  top: 8vh;
  right: 10vw;
  animation: perspec-middle 5s linear infinite;
}

.box-dot::before{
  content: '';
  width: 10px;
  height: 10px;
  background-color: $brand-color;
  position: absolute;
  top: 22vh;
  right: 18vw;
  animation: perspec-back 10s linear infinite;
}

.official-partner {
  position: relative;
  text-align: center;

  .red{
    width: 30px;
    height: 30px;
    background-color: $brand-color;
    position: absolute;
    top: -50px;
  }

  .yellow{
    width: 30px;
    height: 30px;
    background-color: $yellow-color;
    position: absolute;
    top: -30px;
    right: 0px;
  }

  .blue{
    width: 20px;
    height: 20px;
    background-color: $blue-color;
    position: absolute;
    bottom: 0px;
    left: 20px;
  }

  .green{
    width: 40px;
    height: 40px;
    background-color: $green-color;
    position: absolute;
    bottom: -30px;
    right: 30px;
  }

  img{
    width: 80%;
    position: relative;
    z-index: 2;
    margin: auto;
  }
}

.partner img{
  width: 100% !important;
  height: auto !important;
  padding: 10px;
}

.box{
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;

  img{
    width: 60px;
    height: 60px;
  }
}

.category{
  .row {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .row > [class*='col-'] {
    display: flex !important;
    flex-direction: column !important;
  }

  .box{
    height: 100%;
  }
}

.exp{
  background-color: $bg-pink;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 10rem;

  img{
    width: 90%;
    height: auto;
    margin-top: -7rem;
  }
}

.arrow{
  width: 80px;
  float: right;
}

.box-wrap{

  .row {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .row [class*='col-'] {
    display: flex !important;
    flex-direction: column !important;
  }

  .box{
    height: 100%;
    padding: 10px;
    
    img{
      border: none !important;
    }
    
    .box-icon{
      width: 40px !important;
      height: 40px !important;
    }
  
    .title{
      font-size: 1rem;
      font-family: 'Bold';
    }
  
    p{
      font-size: .8rem !important;
    }
  }
}

.product{
  .box{
    padding: 0px;
    overflow: hidden;
  }

  img{
    width: 100%;
    height: auto;
  }

  .body{
    padding: 15px;
  }
}

.solution{
  .row {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .row [class*='col-'] {
    display: flex !important;
    flex-direction: column !important;
  }
  
  .box{
    text-align: center;
    height: 100%;
    padding: 20px 10px;

    strong{
      font-size: 14px;
    }

    img{
      align-self: center !important;
      margin: auto !important;
    }
  }
}

.mobile {
  display: none;
}
.non-mobile{
  display: block;
}

.studies{
  .img-studies{
    width: 100%;
    height: auto;
  }

  .border-image{
    border: 1px solid #ccc;
  }

  .download{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
  }

  strong{
    font-family: 'Lora-SemiBold';
    font-size: 1.2rem;
  }
}

.explore {
  .box1{
    width: 10vw;
    height: 10vw;
    position: absolute;
    border: 1px solid #313131;
    opacity: .5;
    rotate: 30deg;
    left: -30px;
    top: -40px;
  }

  .box2{
    width: 70px;
    height: 70px;
    position: absolute;
    border: 1px solid #313131;
    opacity: .5;
    rotate: 30deg;
    right: -10px;
    bottom: 40px;
  }

  .box3{
    width: 40px;
    height: 40px;
    position: absolute;
    border: 1px solid #313131;
    opacity: .5;
    rotate: 10deg;
    right: -10px;
    bottom: 160px;
  }
}

.about{
  .img-about{
    img{
      width: 140%;
      height: auto;
      margin-left: -45%;
    }
  }

  .desc{
    font-family: 'Lora-Regular';
    color: #404040;
    font-size: 1.4rem;
  }

  .slick-dots{
    bottom: -30px;
  }

  .slick-dots li button:before{
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }

  .box{
    padding: 15px;
    background-color: #fff;
    border-radius: 12px;
  }

  .img-statistic{
    width: 100%;
    height: auto;
  }

  .mg-img{
    width: 100%;
    height: auto;
  }

  .social{
    margin: 0px 5px;
    background-color: #F04438;
    padding: 1px 2px 3px 2px;
    border-radius: 50%;
    
    img{
      width: 25px;
      height: 25px;
    }
  }

  .mg-wrap{
    .box{
      strong{
        font-size: 1rem !important;
      }
    }
  }
}

.flip-card-outer {
  position: relative;

  .flip-card-inner {
    backface-visibility: visible;
    transform-style: preserve-3d;
    transition: .2s linear .1s;
    position: absolute;
    right: 0;
    width: inherit;
    height: inherit;

    &.flip {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.accelerator-img{
  width: 100% !important;
  height: auto !important;
  border: none !important;
}

.accordion{
  --bs-accordion-active-bg: #dbdbdb !important;
  --bs-accordion-active-color: #000 !important;
  --bs-accordion-btn-focus-border-color: $brand-color !important;

  .accordion-header{
    font-family: 'Lora-Regular';
    font-size: 1.3rem;
  }

  .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon);
  }

  .accordion-button:focus{
    box-shadow: none;
  }
}

.solution-wrap{
  width: 100%;
  overflow: hidden;
}

.contact{
  width: 100%;
  overflow: hidden;

  .help-header{
    background-color: $brand-color;
    padding: 15px;
    padding-top: 40px;

    .relative{
      &::after{
        content: '';
        width: 400px;
        top: -40px;
        bottom: -15px;
        background-color: $brand-color;
        position: absolute;
        margin-left: -400px;
        z-index: -1;
      }
    }

    .box-yellow{
      width: 70px;
      height: 56px;
      background-color: $yellow-color;
      position: absolute;
      top: -65px;
      left: -63px;
    }
  }
}

.slick-same-height{
  .slick-track {
    display:flex !important;
    flex-direction: row !important;

    .slick-slide {
      height: auto;
    }

    .slick-slide > div, .slick-slide > div > div, .slick-slide > div > div > div {
      height: 100%;
    }

    .more{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $brand-color;
    }
  }
}

.image-solution{
  width: 70%;
  position: absolute;
  top: -170px;
  right: -400px;
}

.back-wrapper, .back-wrapper:hover{
  color: $brand-color;
  text-decoration: none !important;

  img{
    width: 60px;
    rotate: 180deg;
  }
}

// FOOTER
.footer{
  background-color: #000;

  .social{
    margin-right: 15px;
    margin-left: 15px;
    width: 25px;
    height: 25px;
  }

  .hr-white{
    height: 1px;
    background-color: #fff;
  }
}

.key-number{
  background-color: #FEF3F2;
  padding: 7px 15px;
  border-radius: 5px;
  color: #7b7b7b !important;
  font-family: 'Lora-SemiBold';
}

.customers{
  img{
    width: 70% !important;
    height: auto !important;
    margin: auto !important;
  }
}
/* ==== START MEDIA QUERY ====*/

@media only screen and (min-width: 1400px) {
  .container{
    max-width: 1100px !important;
  }
}

@media only screen and (max-width: 990px) {
  .navbar-nav{
    --bs-nav-link-padding-y: 1rem !important;

    .me-5{
      margin-right: 0px !important;
    }

    button{
      width: 200px;
      margin: auto;
    }
  }

  .exp{
    margin-top: 5rem;

    img{
      margin-top: 0px;
    }
  }

  .partner img{
    padding: 0px;
  }

  .about{
    .img-about{
      img{
        width: 130%;
        height: auto;
        margin-left: -100px;
      }
    }
  }
}

.modal-business{
  p{
    color: #000;
  }

  ol{
    margin-top: 15px;
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-nav{
    --bs-nav-link-padding-y: 1rem !important;

    .me-5{
      margin-right: 0px !important;
    }

    button{
      width: 200px;
      margin: auto;
    }
  }

  .official-partner {
    .red{
      left: 80px;
      top: 0px;
      position: absolute;
    }

    .yellow{
      right: 40px;
      top: 0px;
      position: absolute;
    }

    .blue{
      left: 120px;
      bottom: 30px;
      position: absolute;
    }

    .green{
      position: absolute;
      right: 90px;
      bottom: 30px;
    }

    img{
      width: 50%;
      margin: auto;
      margin-bottom: 40px;
    }
    
  }

  .box-wrap{
    .box-icon{
      width: 50px !important;
      height: 50px !important;
      margin: auto;
    }
  }

  .header{
    font-size: 1.6rem;
  }

  .subheader{
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .text-center-mobile {
    text-align: center;
  }

  .btn-category{
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
  }

  .exp{
    background: rgb(254,243,242);
    background: linear-gradient(0deg, rgba(254,243,242,1) 0%, rgba(254,243,242,1) 70%, rgba(255,255,255,0) 98%);
    margin-top: 1rem;

    img{
      width: 80% !important;
      margin-left: 10% !important;
      margin-bottom: 20px;
    }

    .subheader{
      margin-top: 10px;
    }
  }

  .arrow{
    display: none;
  }

  .mobile{
    display: block;
  }
  .non-mobile{
    display: none;
  }

  .about{
    .img-about{
      display: none;
    }

    .desc{
      font-family: 'Regular';
      color: #757575;
      font-size: 1rem;
    }

    .slide{
      background: rgb(254,243,242);
      background: linear-gradient(0deg, rgba(254,243,242,1) 0%, rgba(254,243,242,1) 70%, rgba(255,255,255,0) 98%);
    }
  }

  .customers{
    img{
      width: 90% !important;
    }
  }

  .mg-wrap{
    background-color: #fff;
    .pe-5{
      padding-right: 0px !important;
    }
    .box{
      width: 60%;
      margin: auto;
    }

    .row{
      .margin-top-mobile{
        margin-top: 5rem !important;
      }
    }

    .mg-mobile{
      background: url("./../assets//managament/bg.png") no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 40vw;
      border-radius: 20px;

      img{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 58vw;
        max-width: 300px;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .accelerator-img{
    width: 100%;
  }

  .product{
    .box{
      position: relative;
      overflow: hidden;
      height: 300px;
      cursor: pointer;

      img{
        width: 100%;
        height: 300px;
        object-fit: cover;
      }

      .body{
        .title{
          display: none;
        }

        .desc{
          display: none;
        }
      }

      &:hover{
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: .7;
        }


        &.box {
          @keyframes slideUp {
            0% { top: 300px; }
            100% { top: 0; }
          }

          .body{
            position: absolute !important;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            animation: slideUp 1s;

            .title, .desc{
              animation: fadeIn 2s;
              display: block;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .contact{
    .pe-5{
      padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .ps-4{
      padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .help-header .relative::after{
      width: 140vw !important;
      margin-left: -20vw;
      margin-right: -20vw;
    }
  }

  .use-case{
    .row{
      margin-bottom: 10px;
      align-items: center;
    }

    .ps-4{
      padding-left: 0px !important;
    }
  }

  // FOOTER
  .footer{
    .flex-row-mobile{
      flex-direction: column !important;
    }

    .text-center{
      margin-top: 40px;
      text-align: left !important;
    }

    .social:first-child{
      margin-left: 0px;
    }
  }
}