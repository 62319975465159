.container-solution {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // transform: skew(-5deg);

  .card {
    flex: 1;
    transition: all 1s ease-in-out;
    height: 75vmin;
    position: relative;

    .card__head {
      color: #fff;
      background: rgba(214, 38, 43, 0.75);
      padding: 0.5em;
      transform: rotate(-90deg);
      transform-origin: 0% 0%;
      transition: all 0.5s ease-in-out;
      min-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 1em;
      white-space: nowrap;
    }

    .card__desc {
      display: none;
      padding: 15px;
    }

    &:hover {
      flex-grow: 10;

      img {
        filter: grayscale(0);
      }

      .card__head {
        position: relative;
        text-align: center;
        top: 0;
        color: white;
        background: rgba(0, 0, 0, 0.5);
        font-size: 2em;
        transform: rotate(0deg) skew(0deg);
      }

      .card__desc {
        display: block;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
      }

      .card__content {
        display: block;
        position: absolute;
        bottom: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease-in-out;
      filter: grayscale(100%);
    }

    &:not(:last-child) {
      margin-right: .5em;
    }
  }
}
