@import "variables";

.banner-wrapper{
  width: 100% !important;
  max-width: 1600px;
  overflow: hidden !important;
  margin: auto;

  .zoom{
    position: relative;
    scale: 1.1;
  }

  .oval{
    width: 100%;
    max-width: 1600px;
    height: 6vw;
    background-color: $bg-pink;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: -2.4vw;
  }

  .oval-bottom{
    width: 100%;
    max-width: 1600px;
    height: 5vw;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    bottom: -2.4vw;
  }
}

.slick-slide {
  box-sizing: border-box;
}

.slick-slide img {
  height: 290px;
  width: 100%;
  border: 1rem solid transparent;
  border-bottom: 0px;
  box-sizing: border-box;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .banner-wrapper{
    .oval{
      top: 0vw !important;
    }
  }
}