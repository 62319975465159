.ttp-container {
  background-color: #ffebef;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo{
  width: 180px;
}

.btn-ttp{
  background-color: #8c2339;
  color: #fff;
}